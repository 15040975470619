import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1280.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)">
<path d="M6200 12218 c-3 -3 -70 -8 -150 -12 -431 -19 -901 -100 -1337 -230
-29 -9 -74 -22 -100 -30 -105 -31 -353 -124 -518 -194 -165 -69 -473 -222
-580 -286 -41 -25 -77 -46 -79 -46 -17 0 -359 -225 -502 -331 -96 -71 -314
-245 -364 -290 -222 -202 -479 -462 -600 -608 -25 -30 -63 -75 -84 -100 -59
-69 -232 -302 -312 -421 -95 -140 -166 -257 -294 -485 -113 -200 -280 -580
-361 -820 -11 -33 -24 -69 -29 -80 -9 -19 -73 -226 -90 -290 -57 -213 -51
-188 -98 -420 -57 -283 -84 -479 -103 -761 -15 -219 -6 -796 15 -990 80 -741
257 -1355 576 -1999 148 -300 342 -624 487 -814 6 -9 42 -56 79 -106 201 -269
491 -586 748 -816 28 -24 57 -51 66 -59 116 -107 341 -280 535 -412 573 -390
1179 -663 1845 -831 121 -31 193 -47 340 -77 97 -20 389 -60 595 -82 156 -17
856 -17 1015 0 243 26 559 72 665 97 33 8 76 17 95 20 56 11 243 55 270 65 14
5 59 18 100 30 674 197 1302 511 1865 934 66 49 154 119 195 154 41 35 102 87
135 115 252 216 579 568 801 862 205 272 454 675 569 920 86 184 113 245 157
350 64 152 137 357 203 565 10 30 39 142 66 249 68 269 110 520 151 896 18
163 18 895 0 1065 -24 235 -55 441 -97 650 -28 141 -44 205 -91 380 -33 123
-112 371 -135 425 -14 35 -91 228 -105 267 -62 168 -259 554 -400 783 -122
197 -187 294 -290 430 -21 28 -59 77 -84 110 -48 65 -91 117 -209 254 -93 107
-397 412 -511 512 -47 41 -87 77 -90 80 -5 6 -299 239 -350 277 -14 11 -92 64
-175 119 -166 111 -246 160 -430 263 -90 51 -477 240 -491 240 -2 0 -23 9 -47
19 -104 48 -211 88 -442 166 -549 184 -1102 277 -1740 291 -154 4 -282 4 -285
2z m560 -263 c652 -43 1295 -201 1876 -460 696 -311 1334 -765 1828 -1304 595
-648 1020 -1401 1262 -2234 146 -505 216 -998 217 -1532 0 -807 -154 -1537
-480 -2275 -144 -325 -314 -626 -526 -929 -118 -168 -197 -270 -326 -421 -12
-14 -48 -55 -79 -92 -85 -98 -362 -371 -487 -479 -794 -685 -1686 -1110 -2705
-1288 -342 -60 -583 -81 -940 -81 -708 0 -1362 120 -2010 370 -387 150 -799
363 -1091 566 -617 428 -1061 863 -1455 1424 -574 817 -917 1778 -995 2785
-14 187 -14 647 0 820 79 936 352 1764 839 2545 549 879 1349 1593 2294 2049
863 416 1816 600 2778 536z"/>
<path d="M5251 10838 c-5 -13 -24 -77 -42 -141 l-32 -117 -149 0 -148 0 0 -38
c0 -36 5 -41 83 -100 45 -34 92 -70 105 -80 l23 -18 -30 -119 c-17 -66 -31
-135 -31 -152 0 -31 2 -33 38 -33 36 0 88 34 200 131 15 13 28 17 35 11 7 -5
50 -39 95 -75 67 -53 91 -67 118 -67 34 0 34 0 34 49 0 26 -12 95 -26 152 -26
102 -26 103 -7 119 11 8 57 44 102 79 77 60 81 66 81 102 l0 39 -145 0 -144 0
-10 38 c-6 20 -24 82 -39 137 l-29 100 -36 3 c-31 3 -38 -1 -46 -20z m73 -100
c13 -46 32 -109 40 -140 l16 -57 145 -3 145 -3 -91 -70 c-50 -38 -99 -77 -110
-85 -19 -15 -19 -18 16 -154 20 -76 34 -141 31 -143 -3 -3 -49 29 -103 72 -54
43 -103 82 -108 86 -6 5 -56 -28 -119 -77 -59 -47 -110 -84 -112 -81 -4 4 41
198 62 268 3 11 -29 41 -107 100 l-111 84 138 3 c76 1 143 7 150 13 6 5 14 24
18 41 9 45 66 228 71 228 2 0 15 -37 29 -82z"/>
<path d="M6237 10853 c-2 -4 -21 -68 -42 -140 l-38 -132 -146 -3 -146 -3 -3
-32 c-3 -29 3 -37 84 -100 48 -37 96 -75 107 -84 19 -15 19 -17 -12 -138 -40
-154 -39 -175 9 -179 30 -2 48 7 130 72 53 42 99 71 105 67 24 -18 110 -86
115 -91 25 -28 74 -51 102 -48 45 4 46 27 9 176 -17 66 -31 123 -31 125 0 3
40 34 88 71 111 82 124 97 120 133 l-3 28 -146 3 -146 3 -39 137 -39 137 -37
3 c-20 2 -38 -1 -41 -5z m72 -130 c16 -54 35 -117 41 -140 l11 -42 145 -3 145
-3 -111 -85 c-60 -47 -108 -90 -106 -96 15 -39 67 -266 62 -271 -3 -3 -53 33
-112 81 -70 57 -110 83 -118 78 -6 -5 -54 -44 -106 -86 -86 -69 -110 -85 -110
-71 0 3 16 65 35 138 19 72 35 135 35 139 0 4 -50 44 -111 90 l-110 83 145 3
145 3 25 82 c13 45 31 108 40 140 9 31 18 57 21 57 2 0 18 -44 34 -97z"/>
<path d="M7201 10791 c-11 -39 -30 -102 -42 -141 l-21 -70 -144 0 -144 0 0
-39 c0 -39 3 -42 100 -116 55 -43 100 -82 100 -89 0 -6 -11 -53 -25 -104 -14
-51 -25 -116 -25 -143 l0 -49 34 0 c32 0 102 46 207 137 15 12 27 5 110 -62
80 -63 100 -75 132 -75 36 0 37 1 37 35 0 19 -11 77 -24 128 -13 50 -27 104
-30 118 -5 23 4 33 99 105 101 76 105 80 105 116 l0 38 -148 0 -148 0 -28 103
c-45 165 -48 172 -89 175 l-35 3 -21 -70z m81 -36 c9 -27 26 -87 39 -132 l23
-83 145 0 146 -1 -26 -22 c-14 -12 -59 -47 -99 -77 -40 -30 -78 -60 -84 -67
-10 -9 -5 -41 22 -147 19 -75 33 -139 30 -142 -3 -3 -54 33 -113 80 l-107 86
-57 -45 c-120 -97 -162 -128 -166 -123 -2 2 11 62 30 133 19 72 35 135 35 142
0 6 -50 50 -111 97 l-111 85 147 3 148 3 40 138 c22 76 43 134 47 130 4 -4 14
-30 22 -58z"/>
<path d="M8166 10733 c-20 -71 -39 -134 -41 -140 -3 -10 -42 -13 -150 -13
l-145 0 0 -39 c0 -36 4 -41 100 -115 58 -45 100 -84 100 -94 0 -9 -11 -60 -25
-112 -14 -52 -25 -114 -25 -137 0 -43 0 -43 35 -43 30 0 52 13 130 76 l94 75
94 -75 c79 -63 100 -76 131 -76 35 0 36 1 36 39 0 21 -13 89 -29 149 l-28 111
26 24 c14 13 61 50 104 82 73 54 77 59 77 96 l0 39 -147 0 -148 0 -39 140 -39
140 -37 0 -37 0 -37 -127z m120 -53 l39 -139 145 -3 145 -3 -75 -57 c-41 -31
-91 -70 -110 -86 l-34 -29 34 -136 c19 -75 31 -139 28 -142 -3 -4 -51 30 -105
74 -55 45 -106 81 -113 81 -7 0 -58 -36 -112 -80 -54 -44 -102 -80 -107 -80
-5 0 6 59 25 131 19 71 34 136 34 144 0 8 -48 52 -107 97 l-108 83 144 5 144
5 12 35 c7 20 25 82 41 138 16 56 31 102 34 102 4 0 24 -63 46 -140z"/>
<path d="M4274 10783 c-9 -32 -26 -94 -38 -138 l-21 -80 -147 -3 -148 -3 0
-35 c0 -40 1 -41 126 -135 l81 -61 -28 -112 c-16 -61 -29 -130 -29 -153 0 -43
0 -43 36 -43 29 0 48 10 88 43 28 23 70 58 94 76 l42 33 95 -76 c77 -63 100
-76 130 -76 35 0 35 0 35 43 0 23 -13 92 -29 153 l-28 111 103 79 c100 75 104
80 104 116 l0 38 -144 0 -144 0 -15 48 c-8 26 -28 89 -43 140 l-27 92 -38 0
c-37 0 -38 -1 -55 -57z m94 -90 c17 -60 34 -120 38 -135 l6 -28 144 0 c83 0
144 -4 144 -9 0 -6 -42 -42 -94 -81 -52 -39 -100 -77 -106 -85 -9 -11 -5 -42
20 -137 17 -68 33 -131 36 -140 3 -10 2 -18 -3 -18 -4 0 -56 39 -115 86 l-108
86 -107 -86 c-60 -47 -111 -86 -115 -86 -4 0 9 58 28 130 19 71 34 138 34 148
0 11 -40 47 -105 96 -58 43 -105 82 -105 87 0 5 65 9 143 9 l144 0 31 108 c17
59 34 119 38 135 3 15 9 27 13 27 4 0 22 -48 39 -107z"/>
<path d="M8748 10340 c-20 -11 -49 -36 -64 -56 -33 -43 -42 -115 -25 -219 15
-96 0 -155 -51 -211 -105 -113 -271 -120 -933 -38 -644 80 -690 84 -950 84
-691 0 -1217 -214 -1561 -635 -94 -115 -204 -316 -254 -466 -67 -199 -75 -463
-18 -648 22 -75 74 -173 112 -213 33 -35 101 -74 144 -83 50 -10 125 12 171
52 53 45 71 80 71 141 0 77 -25 123 -141 254 -85 95 -106 132 -125 215 -18 83
-16 147 11 275 51 248 243 481 505 612 204 103 450 159 806 187 200 15 530 8
549 -13 11 -11 6 -41 -25 -163 -21 -82 -95 -382 -164 -665 -70 -283 -142 -576
-161 -650 -19 -74 -57 -223 -84 -330 -129 -508 -218 -831 -278 -1010 -77 -229
-191 -351 -378 -406 -33 -9 -114 -27 -179 -38 l-120 -21 -13 -50 c-18 -71 -17
-95 5 -95 10 0 65 6 123 14 219 31 479 40 704 27 116 -7 226 -16 245 -20 l35
-7 -3 -75 c-11 -291 89 -506 278 -600 121 -60 273 -63 387 -8 62 29 183 151
210 210 55 117 65 239 37 428 -25 172 -78 337 -125 393 l-26 33 5 -140 c10
-280 -43 -486 -155 -606 -56 -59 -98 -84 -173 -97 -145 -27 -263 80 -319 289
-19 70 -25 110 -22 153 1 17 10 18 164 12 133 -6 186 -3 296 13 74 11 139 25
146 33 19 19 55 157 44 164 -5 3 -24 1 -42 -5 -17 -6 -75 -18 -127 -27 -114
-21 -452 -26 -470 -8 -15 15 18 152 66 277 83 216 178 368 346 551 177 193
309 302 464 386 314 171 593 262 847 277 124 7 127 5 69 -66 -158 -190 -325
-488 -440 -786 -110 -283 -271 -941 -377 -1540 -62 -351 -105 -541 -166 -735
-46 -141 -99 -280 -109 -280 -17 0 -58 52 -84 107 -50 101 -42 249 20 359 31
55 30 89 -1 119 -44 45 -115 23 -150 -45 -22 -43 -19 -190 5 -296 23 -97 82
-219 135 -278 19 -21 35 -47 35 -57 0 -65 -203 -339 -251 -339 -10 0 -47 18
-81 41 -184 119 -413 190 -618 191 -179 1 -318 -86 -335 -211 -17 -121 92
-230 285 -287 51 -15 95 -19 210 -18 136 0 151 2 240 33 52 17 137 54 188 82
106 56 85 59 226 -33 119 -79 345 -188 481 -234 131 -44 296 -59 535 -50 279
11 424 63 765 278 252 158 412 241 567 293 88 29 120 35 191 35 185 0 327
-101 327 -234 0 -44 -4 -53 -39 -86 -29 -28 -53 -40 -100 -50 -67 -14 -93 -37
-83 -73 17 -67 137 -104 234 -72 103 33 168 121 168 229 0 99 -30 168 -104
241 -108 107 -211 141 -406 132 -166 -7 -333 -56 -550 -162 -238 -116 -489
-212 -655 -250 -365 -84 -647 -91 -931 -25 -149 36 -419 146 -431 177 -3 7 24
45 59 85 35 40 89 110 121 156 32 45 63 82 68 82 6 0 41 -18 78 -40 327 -191
681 -263 1051 -215 236 31 322 54 580 156 285 112 430 194 611 346 319 270
579 679 649 1023 41 203 49 490 18 680 -44 275 -165 601 -300 810 -115 179
-296 374 -458 496 -223 168 -471 278 -730 324 -69 12 -135 24 -147 26 -13 3
-23 11 -23 18 0 26 139 165 281 281 86 70 277 183 418 246 126 57 127 57 120
64 -11 10 -194 -47 -313 -98 -213 -92 -389 -209 -575 -384 l-116 -110 -125 -7
c-256 -14 -433 -57 -676 -164 -286 -126 -447 -229 -620 -398 -198 -193 -329
-379 -414 -591 -31 -75 -97 -271 -117 -343 -8 -30 -13 -35 -37 -33 -16 1 -31
4 -34 7 -2 3 7 56 22 118 14 62 50 219 80 348 30 129 84 350 120 490 36 140
97 374 135 520 80 311 186 718 246 950 23 91 66 255 94 365 51 194 95 350 110
389 7 18 24 19 402 20 428 2 507 10 705 70 185 55 328 159 410 298 l37 63 1
127 c1 125 1 127 -29 169 -58 83 -173 113 -257 69z m168 -2551 c102 -31 267
-105 369 -167 296 -178 511 -427 640 -738 112 -269 137 -464 100 -779 -52
-441 -248 -911 -548 -1312 -121 -163 -239 -277 -407 -395 -336 -235 -734 -304
-1155 -202 -127 30 -194 55 -261 96 -59 35 -64 47 -35 87 37 52 201 378 276
551 42 96 83 189 91 205 7 17 38 94 68 171 161 418 286 870 376 1359 69 378
168 715 274 930 63 128 118 215 135 215 5 0 40 -9 77 -21z m-2158 -3695 c94
-26 318 -132 347 -164 22 -24 13 -37 -52 -73 -112 -61 -338 -113 -438 -101
-183 22 -330 103 -350 192 -16 75 68 154 181 172 85 13 213 3 312 -26z"/>
<path d="M3110 7926 c-132 -37 -229 -99 -362 -231 -49 -47 -88 -88 -88 -91 0
-2 55 38 123 89 125 94 235 153 344 183 94 25 276 30 383 9 141 -27 129 -2
-17 35 -90 23 -309 26 -383 6z"/>
<path d="M3834 7860 c-5 -5 -8 -28 -6 -50 4 -36 10 -45 46 -67 22 -13 60 -38
83 -54 37 -26 53 -30 163 -39 134 -11 139 -12 260 -47 299 -86 594 -290 787
-543 89 -118 192 -346 223 -495 9 -45 17 -58 36 -66 39 -14 94 -11 100 6 4 8
0 198 -6 422 -7 225 -16 527 -19 673 l-6 265 -38 3 c-50 4 -63 -8 -77 -71 -39
-163 -134 -233 -281 -207 -71 13 -272 81 -338 114 -110 56 -412 134 -596 156
-114 13 -318 13 -331 0z"/>
<path d="M3222 7780 c-72 -14 -170 -47 -212 -73 -19 -12 -48 -29 -64 -37 -49
-25 -179 -163 -212 -226 -75 -141 -112 -305 -99 -441 32 -341 260 -622 678
-835 31 -16 57 -35 57 -42 0 -8 -12 -22 -27 -31 -62 -41 -128 -135 -121 -173
4 -24 50 -27 89 -6 26 13 47 15 101 9 80 -7 153 10 213 51 45 30 71 29 158 -9
67 -30 74 -52 20 -61 -76 -14 -215 -91 -291 -162 -84 -77 -119 -125 -153 -209
-20 -51 -24 -76 -23 -165 0 -92 3 -112 27 -163 77 -169 223 -280 422 -322 271
-57 515 79 566 315 17 78 -2 144 -59 216 -67 83 -127 118 -211 122 -56 3 -69
1 -88 -18 -79 -74 79 -227 202 -196 15 4 35 21 47 42 24 41 37 40 54 -7 64
-176 -87 -374 -312 -409 -210 -33 -440 95 -515 287 -28 71 -30 197 -5 256 60
138 178 250 337 321 75 33 117 43 222 55 74 9 107 7 200 -9 62 -10 153 -24
202 -30 261 -33 300 -40 364 -72 35 -18 65 -38 68 -45 3 -7 -11 -28 -31 -46
-47 -45 -66 -93 -66 -167 0 -102 34 -129 84 -70 15 19 54 43 97 60 81 34 121
72 146 142 10 26 22 49 28 52 16 6 101 -32 164 -73 68 -44 158 -127 149 -136
-4 -4 -34 0 -66 9 -170 47 -282 -3 -282 -127 0 -113 108 -145 270 -82 74 29
156 33 220 10 88 -31 140 -44 145 -35 9 15 -271 317 -363 391 -23 19 -75 52
-115 75 -76 43 -77 45 -53 109 8 22 15 67 16 100 0 67 -13 95 -78 162 -22 23
-45 58 -52 77 -27 84 -50 23 -50 -135 l0 -120 -55 31 c-46 26 -62 31 -94 25
-30 -5 -49 -1 -81 15 -23 12 -65 24 -93 27 -29 3 -62 12 -74 19 -37 23 -44 18
-37 -28 4 -24 11 -53 15 -65 11 -30 -1 -35 -63 -24 -247 41 -423 75 -487 92
-131 35 -240 70 -246 78 -2 5 20 34 50 66 68 71 82 112 65 202 -7 34 -9 83 -6
108 4 25 3 45 -2 45 -24 0 -148 -158 -176 -225 -16 -36 -36 -40 -36 -6 0 50
-21 80 -68 96 -34 11 -55 27 -75 55 -16 22 -46 49 -66 60 -21 10 -46 31 -55
46 -23 33 -35 22 -65 -59 -23 -64 -27 -123 -9 -162 6 -14 9 -28 5 -31 -8 -8
-141 63 -163 87 -13 15 -17 36 -17 91 1 63 5 76 32 115 36 51 38 59 11 73 -66
35 -114 -91 -75 -199 16 -47 8 -52 -40 -22 -45 28 -175 158 -225 226 -54 73
-124 230 -139 310 -25 136 -2 265 77 437 85 184 266 311 462 325 91 6 163 -17
229 -73 169 -145 120 -363 -93 -414 -50 -12 -63 -12 -99 1 -49 18 -60 39 -22
39 70 0 145 66 145 128 0 77 -60 132 -146 132 -90 0 -160 -62 -158 -140 2
-141 170 -242 326 -196 68 20 147 87 179 152 44 91 9 224 -77 291 -19 16 -47
38 -62 50 l-27 23 34 0 c50 0 149 -28 219 -62 125 -61 182 -168 182 -338 0
-82 2 -90 19 -90 11 0 23 5 26 10 14 23 32 5 74 -70 24 -44 64 -100 89 -125
41 -42 168 -125 191 -125 27 0 -45 129 -122 217 l-32 36 43 -6 c57 -10 55 5
-14 73 -30 30 -85 100 -121 154 -36 55 -89 124 -117 154 -86 92 -243 183 -384
222 -83 23 -271 34 -350 20z"/>
<path d="M2549 7549 c-18 -34 -3 -88 33 -126 40 -42 54 -42 67 0 14 42 -5 95
-45 126 -33 26 -40 26 -55 0z"/>
<path d="M3003 7458 c-81 -90 -146 -299 -135 -438 5 -66 12 -76 43 -59 41 21
92 29 132 18 48 -13 104 -62 112 -98 3 -15 25 -43 50 -64 24 -20 46 -46 50
-56 3 -11 14 -23 24 -26 10 -3 35 -21 54 -40 28 -27 44 -35 73 -35 43 0 111
-36 120 -62 3 -10 18 -26 32 -35 15 -9 50 -39 79 -66 51 -50 52 -50 77 -33 34
22 33 33 -5 54 -80 44 -186 109 -209 127 -14 11 -68 62 -121 115 -81 81 -99
105 -123 164 -15 38 -30 97 -33 131 -6 62 -6 63 -54 90 -55 33 -112 103 -135
166 -15 42 -14 112 2 154 9 22 -10 18 -33 -7z"/>
<path d="M2455 7323 c4 -21 15 -49 25 -63 24 -34 87 -67 101 -53 12 12 3 71
-14 93 -24 30 -70 60 -94 60 -24 0 -25 -2 -18 -37z"/>
<path d="M2410 7150 c-22 -14 28 -87 75 -110 50 -24 85 -26 85 -4 0 26 -28 78
-50 91 -28 18 -96 32 -110 23z"/>
<path d="M2426 6941 c-15 -24 39 -88 81 -96 13 -3 34 -7 48 -10 52 -11 41 61
-14 89 -40 21 -107 31 -115 17z"/>
<path d="M2957 6850 c-48 -56 -58 -148 -22 -196 13 -18 14 -17 15 16 0 62 19
108 56 138 42 35 43 65 3 70 -19 2 -33 -5 -52 -28z"/>
<path d="M2483 6750 c-29 -12 -29 -21 0 -48 48 -45 142 -67 166 -38 21 25 -73
94 -129 95 -8 0 -25 -4 -37 -9z"/>
<path d="M3063 6728 c-33 -36 -43 -63 -43 -113 0 -46 22 -105 40 -105 5 0 7
13 4 30 -10 48 4 95 41 135 39 40 40 46 23 63 -18 18 -42 14 -65 -10z"/>
<path d="M2427 6565 c-90 -33 -128 -84 -129 -169 -1 -113 70 -176 197 -176 48
0 70 5 96 23 69 46 79 119 25 173 -19 19 -40 34 -47 34 -9 0 -6 -7 7 -18 52
-41 57 -96 14 -145 -53 -61 -144 -55 -211 12 -49 48 -58 94 -31 153 20 46 97
108 133 108 10 0 21 5 24 10 9 14 -33 11 -78 -5z"/>
<path d="M4043 6303 c9 -42 7 -56 -16 -113 -15 -35 -25 -65 -24 -66 6 -6 454
-104 474 -104 3 0 2 13 -2 29 -5 22 0 35 21 60 15 19 23 36 18 41 -5 5 -63 31
-129 58 -66 28 -167 71 -224 96 -57 25 -109 46 -116 46 -9 0 -9 -12 -2 -47z"/>
<path d="M2845 6213 c-141 -30 -257 -109 -306 -211 -31 -64 -38 -191 -14 -265
21 -64 87 -160 121 -176 44 -20 83 -13 115 18 42 42 38 72 -17 129 -58 59 -84
110 -91 176 -10 99 31 177 126 237 45 29 167 72 234 84 21 3 35 10 32 16 -9
13 -120 10 -200 -8z"/>
<path d="M3095 6141 c-122 -36 -201 -85 -228 -144 -41 -86 0 -216 68 -217 56
-1 81 56 50 110 -8 14 -14 43 -15 64 0 33 6 45 42 79 23 22 80 58 125 79 46
21 83 41 83 43 0 11 -69 3 -125 -14z"/>
<path d="M3822 5767 c-45 -48 -55 -130 -22 -184 14 -23 27 -33 44 -33 35 0 36
17 8 79 -23 49 -24 60 -15 105 6 28 10 52 9 53 -2 2 -12 -7 -24 -20z"/>
<path d="M4003 5763 c-47 -10 -69 -76 -35 -106 14 -13 30 -15 68 -11 32 4 73
0 114 -10 228 -59 375 -285 315 -486 -33 -110 -40 -97 97 -175 250 -143 390
-288 449 -463 17 -52 20 -81 17 -175 -3 -104 -6 -117 -39 -187 -57 -121 -192
-252 -334 -323 -190 -95 -513 -125 -778 -72 -68 13 -163 38 -210 55 -194 68
-427 209 -580 350 -120 111 -257 292 -331 437 -37 73 -120 285 -146 373 -37
128 -33 121 -93 118 l-52 -3 -2 -764 c-2 -601 0 -767 10 -773 6 -4 33 -8 59
-8 44 0 48 2 82 49 86 117 200 183 326 189 89 5 130 -5 400 -94 383 -125 463
-139 840 -139 315 0 359 5 585 61 109 27 189 56 305 110 68 32 217 119 230
134 3 3 46 40 95 82 210 176 342 369 402 588 26 94 26 468 1 551 l-18 56 -59
7 c-33 4 -93 18 -132 31 -92 32 -130 31 -215 -1 -95 -37 -229 -40 -290 -7 -54
29 -111 96 -125 147 -16 59 -16 59 -60 24 -55 -44 -137 -51 -188 -16 -57 38
-75 80 -76 173 0 54 6 99 20 138 16 48 17 60 6 66 -7 5 -64 14 -125 21 -61 6
-169 21 -241 32 -134 21 -253 30 -292 21z"/>
<path d="M3090 5699 c-44 -27 -50 -36 -50 -81 0 -30 6 -42 37 -67 30 -25 47
-31 84 -31 41 0 51 4 78 35 62 71 28 148 -71 161 -33 4 -51 0 -78 -17z"/>
<path d="M3675 5680 c-45 -50 -34 -181 18 -210 26 -14 29 -14 43 4 12 17 12
22 -1 40 -35 47 -47 89 -41 138 4 26 5 48 3 48 -2 0 -12 -9 -22 -20z"/>
<path d="M3530 5540 c-17 -41 -8 -125 17 -158 23 -31 60 -36 77 -10 8 14 5 23
-16 45 -32 34 -48 73 -48 119 0 42 -13 44 -30 4z"/>
<path d="M6824 5053 c-189 -353 -234 -441 -234 -452 0 -6 11 -11 24 -11 26 0
32 10 216 365 40 77 77 144 81 150 17 18 9 35 -15 35 -21 0 -32 -14 -72 -87z"/>
<path d="M6032 5113 c-29 -14 -82 -75 -82 -94 0 -19 32 -15 57 7 32 28 69 31
101 8 17 -12 22 -25 22 -58 0 -60 -55 -139 -175 -255 -52 -49 -95 -97 -95
-106 0 -13 20 -15 130 -15 l130 0 19 38 c32 63 35 82 14 82 -10 0 -26 -9 -36
-20 -13 -16 -29 -20 -72 -20 -30 0 -55 2 -55 4 0 2 38 43 86 91 132 136 166
211 129 289 -29 61 -105 83 -173 49z"/>
<path d="M6424 4958 c-126 -137 -174 -201 -174 -234 0 -2 40 -4 89 -4 l90 0
-19 -66 -19 -65 41 3 41 3 16 60 c15 56 18 60 47 63 26 3 33 9 42 38 10 33 10
34 -20 34 -26 0 -29 3 -22 23 4 12 15 51 25 87 9 36 27 98 38 137 26 89 26 83
-2 83 -16 0 -63 -44 -173 -162z m48 -94 l-19 -69 -52 -3 c-30 -2 -51 1 -50 6
8 19 134 152 137 143 1 -4 -6 -39 -16 -77z"/>
<path d="M6933 5061 c-18 -33 -33 -62 -33 -65 0 -16 41 -3 54 16 14 19 27 23
91 26 54 3 75 1 75 -8 0 -12 -12 -34 -147 -277 -40 -73 -73 -137 -73 -142 0
-9 23 -21 40 -21 9 0 290 502 290 518 0 9 -39 12 -132 12 l-133 0 -32 -59z"/>
<path d="M3678 3040 c-71 -21 -143 -90 -166 -159 -7 -19 -12 -60 -12 -93 0
-90 17 -117 207 -328 13 -14 30 -40 39 -58 15 -31 15 -38 -1 -77 -13 -35 -23
-46 -51 -55 -59 -20 -130 6 -153 56 -6 15 -11 60 -10 101 1 41 -3 79 -8 84 -6
6 -32 9 -59 7 l-49 -3 -17 -55 c-9 -30 -30 -115 -48 -188 -36 -155 -34 -162
41 -162 37 0 51 5 63 21 l14 21 69 -23 c47 -16 90 -23 141 -23 137 1 251 84
288 211 35 119 0 210 -132 348 -126 130 -144 165 -104 205 32 32 101 27 142
-9 30 -27 32 -31 30 -98 -2 -83 7 -96 70 -91 35 2 45 8 56 31 12 26 15 26 35
12 12 -8 38 -15 59 -15 40 0 60 20 73 72 9 36 49 75 86 83 17 4 33 5 35 2 3
-3 -13 -65 -36 -139 -158 -507 -141 -468 -205 -468 -42 0 -46 -2 -60 -37 -9
-21 -15 -49 -13 -63 3 -23 8 -25 78 -33 41 -4 140 -6 220 -5 l145 3 17 35 c34
70 17 100 -57 100 -37 0 -37 1 -31 33 3 17 43 154 89 302 l83 270 42 3 c52 4
58 -7 42 -83 -14 -70 -5 -85 54 -85 51 0 60 6 80 53 l15 38 41 -21 c31 -16 45
-18 60 -10 11 6 20 15 20 20 0 19 62 52 98 52 73 0 93 -75 45 -171 -26 -54
-204 -242 -360 -382 -65 -58 -73 -69 -73 -101 0 -66 8 -68 262 -68 169 0 228
3 235 13 24 30 86 185 87 217 l1 35 -56 3 c-54 3 -57 1 -83 -33 -23 -30 -33
-35 -67 -35 -21 0 -39 2 -39 3 0 2 51 56 114 119 172 175 216 250 216 373 0
179 -174 287 -340 209 -30 -14 -66 -38 -80 -54 -35 -39 -40 -38 -27 5 19 64
23 63 -362 67 l-344 3 -14 -35 -13 -35 5 34 c7 46 -8 61 -65 61 -38 0 -51 -5
-62 -21 l-14 -22 -57 22 c-60 22 -174 28 -229 11z m236 -50 l58 -25 22 28 c22
27 76 39 76 17 0 -6 -16 -76 -36 -155 l-36 -145 -32 0 -31 0 3 57 c3 68 -18
111 -70 143 -80 49 -188 3 -188 -80 0 -45 12 -63 125 -181 109 -115 135 -163
135 -254 0 -102 -45 -174 -136 -220 -76 -39 -168 -40 -273 -5 l-73 24 -18 -22
c-20 -24 -80 -32 -80 -9 1 6 18 82 38 167 l38 155 29 0 30 0 1 -75 c0 -85 10
-108 58 -145 69 -53 175 -40 216 27 44 73 22 128 -109 273 -50 55 -98 116
-107 135 -23 47 -28 107 -14 160 14 53 87 130 140 147 57 19 167 11 234 -17z
m885 -6 c2 -2 -14 -59 -35 -126 -39 -122 -40 -123 -72 -126 l-32 -3 6 68 c5
52 3 71 -7 80 -17 14 -126 20 -138 7 -5 -5 -19 -43 -31 -84 -25 -86 -29 -100
-105 -350 -31 -102 -54 -194 -53 -205 2 -15 14 -22 53 -29 28 -5 51 -10 53
-11 2 -2 0 -14 -4 -29 l-6 -26 -192 0 c-106 0 -195 3 -198 6 -10 10 8 59 23
61 77 10 90 15 104 44 13 24 52 145 201 622 4 14 -1 17 -28 17 -86 0 -159 -49
-178 -121 -10 -36 -14 -39 -45 -39 -19 0 -35 1 -35 3 0 3 55 202 65 238 5 13
42 15 328 11 177 -2 324 -6 326 -8z m305 5 c21 -6 57 -28 80 -50 86 -78 87
-194 3 -320 -37 -56 -185 -213 -274 -291 -29 -25 -53 -49 -53 -52 0 -3 41 -6
91 -6 l91 0 33 36 c26 28 39 35 61 32 16 -2 28 -7 27 -13 -2 -11 -74 -178 -79
-184 -6 -7 -429 0 -436 7 -16 17 8 60 57 100 82 68 267 251 315 312 57 73 80
125 80 185 0 74 -41 115 -113 115 -32 0 -104 -41 -121 -69 -8 -13 -56 7 -56
24 0 17 53 97 83 124 27 24 116 60 152 61 11 0 37 -5 59 -11z"/>
<path d="M5573 2783 c-301 -313 -305 -318 -327 -390 -10 -34 -16 -68 -13 -76
6 -16 88 -27 198 -27 38 0 69 -3 69 -7 0 -5 -11 -44 -25 -88 -35 -113 -33
-115 75 -115 60 0 91 4 97 13 4 6 19 53 34 102 l27 90 48 5 49 5 22 60 c12 33
22 70 22 82 1 24 -25 43 -59 43 -14 0 -20 5 -17 13 2 6 37 117 76 245 83 266
83 262 -9 262 l-57 0 -210 -217z m301 179 c2 -4 -29 -116 -70 -247 -41 -132
-74 -248 -74 -257 0 -14 8 -18 40 -18 46 0 47 -5 24 -73 l-16 -47 -48 0 -49 0
-33 -102 -33 -103 -62 -1 c-75 -2 -73 -6 -34 121 l29 90 -132 3 c-72 1 -136 4
-143 7 -9 3 -7 19 7 61 18 50 41 79 214 258 106 110 218 227 248 259 47 49 60
57 91 57 20 0 38 -4 41 -8z"/>
<path d="M5539 2573 l-114 -118 79 -3 c43 -2 80 -1 83 2 7 6 73 230 69 233 -1
2 -54 -50 -117 -114z m37 -58 c-6 -31 -10 -35 -38 -35 -33 0 -32 2 16 57 25
29 30 23 22 -22z"/>
<path d="M6069 2988 c-9 -7 -35 -49 -59 -93 -23 -44 -52 -97 -63 -117 -28 -51
-13 -68 57 -68 45 0 55 4 83 34 36 39 93 53 161 39 l41 -8 -75 -130 c-252
-430 -296 -510 -287 -524 5 -8 35 -26 67 -39 49 -22 59 -23 71 -11 11 11 170
277 256 427 11 18 69 118 129 221 160 273 160 271 -108 271 -103 0 -203 3
-222 6 -22 3 -41 0 -51 -8z m451 -55 c0 -15 -29 -76 -69 -142 -67 -112 -116
-197 -271 -461 -40 -69 -87 -147 -103 -175 -17 -27 -31 -51 -32 -52 -4 -6 -85
33 -85 41 0 4 28 56 63 115 35 58 74 124 87 146 13 22 70 120 127 218 56 98
103 183 103 188 0 5 -47 9 -105 9 -116 0 -150 -10 -179 -54 -12 -20 -25 -26
-51 -26 -19 0 -35 3 -35 7 0 3 25 53 57 110 l56 103 219 0 218 0 0 -27z"/>
<path d="M6612 2977 c-5 -7 -16 -31 -22 -54 -16 -53 -3 -73 46 -73 38 0 43
-15 23 -67 -13 -35 -113 -353 -140 -447 -32 -111 -43 -126 -90 -126 -38 0 -41
-2 -60 -45 -42 -94 -26 -99 317 -93 272 5 319 15 430 88 164 107 246 248 248
425 1 113 -26 193 -92 266 -91 102 -170 124 -469 133 -135 4 -184 2 -191 -7z
m398 -38 c108 -13 169 -40 223 -98 60 -64 87 -129 94 -221 15 -204 -118 -397
-332 -481 -57 -22 -75 -23 -332 -27 -292 -4 -280 -6 -267 46 5 17 13 22 42 22
19 0 44 7 53 16 18 16 53 116 139 394 23 74 48 155 56 179 8 25 14 59 14 77 0
28 -4 33 -29 39 -15 3 -34 5 -42 3 -13 -3 -15 17 -4 50 6 15 263 17 385 1z"/>
<path d="M6867 2853 c-17 -28 -197 -638 -192 -651 5 -13 20 -14 100 -9 158 11
243 52 308 150 56 84 70 138 71 262 1 97 -2 115 -22 150 -27 47 -76 83 -129
96 -47 11 -130 12 -136 2z m197 -67 c103 -78 79 -346 -41 -470 -21 -22 -57
-47 -80 -58 -46 -20 -192 -44 -216 -35 -13 5 -4 41 49 214 35 114 76 248 91
297 l27 88 68 -4 c53 -4 75 -11 102 -32z"/>
<path d="M7782 2808 c-73 -95 -195 -256 -273 -358 -163 -215 -195 -250 -225
-250 -40 0 -53 -11 -69 -61 -26 -78 -22 -80 169 -77 l160 3 17 45 c19 50 11
80 -21 80 -23 0 -26 22 -7 42 6 7 25 30 40 51 27 36 30 37 93 37 l64 0 0 -49
c0 -71 -6 -81 -50 -81 -44 0 -53 -9 -71 -69 -20 -68 -12 -71 218 -71 l193 0
11 28 c6 15 12 43 13 62 1 34 1 34 -52 43 l-54 9 6 41 c3 23 22 194 42 379 41
378 42 368 -31 368 l-42 0 -131 -172z m198 115 c0 -26 -26 -273 -55 -523 -8
-69 -15 -149 -15 -178 0 -47 3 -53 26 -62 14 -6 37 -10 51 -10 22 0 24 -3 18
-27 -4 -16 -8 -29 -10 -31 -5 -5 -348 -2 -353 3 -2 2 -1 17 3 34 6 27 11 31
39 31 51 0 66 18 76 90 16 108 23 100 -87 100 l-98 0 -53 -72 c-61 -82 -65
-111 -16 -116 32 -3 32 -3 21 -39 l-11 -36 -135 7 c-75 4 -137 9 -140 11 -2 2
-1 15 3 29 5 19 16 28 45 36 49 13 70 35 201 205 138 180 329 432 389 512 37
50 52 63 74 63 23 0 27 -4 27 -27z"/>
<path d="M7728 2546 c-38 -49 -68 -93 -68 -98 0 -5 28 -8 63 -6 l62 3 8 70
c14 129 11 131 -65 31z m32 -50 c0 -16 -18 -31 -27 -22 -8 8 5 36 17 36 5 0
10 -6 10 -14z"/>
<path d="M8202 2943 c-5 -10 -24 -70 -42 -133 -17 -63 -34 -122 -36 -131 -8
-28 23 -49 70 -49 49 0 55 6 80 73 19 52 61 87 104 87 18 0 22 -4 18 -17 -78
-256 -180 -571 -188 -580 -5 -7 -31 -13 -56 -15 -49 -3 -61 -15 -75 -78 -13
-53 -6 -55 230 -55 l218 0 13 29 c8 16 15 43 16 60 1 30 0 31 -52 37 -29 4
-55 9 -58 11 -4 5 158 544 176 586 7 16 18 22 43 22 49 0 55 -11 45 -81 -7
-44 -6 -64 2 -75 16 -18 94 -19 109 0 19 22 101 276 94 292 -3 8 -15 16 -27
19 -11 2 -168 7 -348 10 -309 6 -327 5 -336 -12z m413 -24 c133 0 246 -3 252
-7 7 -5 -3 -48 -30 -129 l-41 -123 -33 0 c-18 0 -33 2 -33 5 0 2 5 27 11 55 9
43 8 55 -5 75 -15 22 -24 25 -80 25 l-63 0 -83 -272 c-116 -381 -115 -377
-103 -389 5 -5 33 -12 61 -16 29 -3 52 -7 52 -9 0 -1 -4 -14 -10 -28 l-10 -26
-195 0 c-170 0 -195 2 -195 16 0 33 20 54 53 54 43 0 70 20 89 67 28 66 191
603 185 609 -12 12 -108 -9 -139 -30 -32 -21 -50 -47 -68 -98 -8 -22 -16 -28
-40 -28 -20 0 -30 5 -30 15 0 16 59 225 67 239 3 5 38 6 77 2 39 -3 179 -6
311 -7z"/>
<path d="M9267 2893 c-29 -38 -118 -153 -197 -258 -340 -450 -345 -456 -415
-467 -13 -2 -25 -19 -38 -53 -18 -50 -18 -50 3 -67 19 -15 44 -18 170 -18 168
0 167 0 177 78 5 49 5 51 -21 58 -14 3 -26 10 -26 14 0 5 18 31 40 59 l40 51
71 0 c69 0 71 -1 65 -22 -3 -13 -6 -35 -6 -50 0 -36 -25 -58 -65 -58 -31 0
-34 -3 -50 -51 -15 -43 -15 -53 -4 -64 10 -9 71 -15 206 -20 l191 -7 17 25
c23 35 30 90 13 104 -7 6 -31 13 -53 15 -46 4 -45 -16 -20 213 59 559 58 554
45 570 -7 9 -29 15 -51 15 -35 0 -42 -5 -92 -67z m113 5 c0 -26 -34 -345 -65
-613 -16 -140 -11 -155 57 -155 40 0 43 -5 31 -48 l-6 -24 -173 4 c-95 1 -176
6 -179 10 -4 4 -5 19 -1 33 5 19 13 25 35 25 44 0 70 28 81 89 5 29 10 67 10
82 l0 29 -95 0 -95 0 -56 -74 c-59 -79 -65 -106 -24 -116 20 -5 25 -12 25 -35
l0 -30 -142 -3 c-152 -3 -150 -3 -137 46 4 13 14 22 26 22 32 0 73 27 111 72
33 40 163 210 432 565 98 129 120 152 140 151 20 -2 25 -8 25 -30z"/>
<path d="M9131 2526 c-39 -53 -71 -98 -71 -101 0 -3 28 -5 62 -3 l63 3 11 95
c6 52 10 96 9 98 -1 1 -34 -40 -74 -92z m29 -56 c0 -11 -7 -20 -15 -20 -18 0
-19 12 -3 28 16 16 18 15 18 -8z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
